import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SalesService {

  private readonly url = 'mag-erp-admin/reports/sales';

  constructor(private http: HttpClient) {
  }

  getSales(params: any): Observable<any> {
    return this.http.post(`${this.url}/sales`, params);
  }

  getAverageSales(fromDate): Observable<any> {
    return this.http.get(`sales-report/sales-by-day?fromDate=${fromDate}`);
  }

  getProductQuantity(params: any): Observable<any> {
    return this.http.post(`${this.url}/product-quantity`, params);
  }

  getOrderQuantity(params: any): Observable<any> {
    return this.http.post(`${this.url}/order-quantity`, params);
  }

  getProductErrorQuantity(params: any): Observable<any> {
    return this.http.post(`${this.url}/product-error`, params);
  }

  getOrderApprovedQuantity(params: any): Observable<any> {
    return this.http.post(`${this.url}/order-approved`, params);
  }

  getSalesByFacility(params: any): Observable<any> {
    return this.http.post(`${this.url}/sales-by-facility`, params);
  }

  getAverageStoreRevenueProduct(params: any): Observable<any> {
    return this.http.post(`sales-report/revenue-store-avg/product`, params);
  }

  getAverageStoreRevenueOrder(params: any): Observable<any> {
    return this.http.post(`sales-report/revenue-store-avg/orders`, params);
  }

}
