import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  private readonly url = 'sales-report';

  constructor(private http: HttpClient) {
  }

  getReport1_1(params: any): Observable<any> {
    return this.http.post(`${this.url}/report-1/1`, params);
  }

  getReport1_2(params: any): Observable<any> {
    return this.http.post(`${this.url}/report-1/2`, params);
  }

  getReport2_1(params: any): Observable<any> {
    return this.http.post(`${this.url}/report-2/1`, params);
  }

  getReport2_2(params: any): Observable<any> {
    return this.http.post(`${this.url}/report-2/2`, params);
  }

  getReport3(params: any): Observable<any> {
    return this.http.post(`${this.url}/report-3`, params);
  }

  getReport4(params: any): Observable<any> {
    return this.http.post(`${this.url}/report-4`, params);
  }

}
