import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ItemsService {

  private readonly url = 'mag-base/enumerationTypes';

  constructor(private http: HttpClient) {
  }

  getList(enumTypeId: string, lazyLoadEvent: string): Observable<any> {
    return this.http.get(`${this.url}/${enumTypeId}/enums?lazyLoadEvent=${lazyLoadEvent}`);
  }

  get(id: string): Observable<any> {
    return this.http.get(`${this.url}/${id}`);
  }

  create(item: any): Observable<any> {
    return this.http.post(`${this.url}`, item);
  }

  update(id: string, item: any): Observable<any> {
    return this.http.put(`${this.url}/${id}`, item);
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${this.url}/${id}`);
  }

  status(id: string, statusId: string): Observable<any> {
    return this.http.patch(`${this.url}/${id}/statusId/${statusId}`, null);
  }
}
