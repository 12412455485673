import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimePeriodService {

  private readonly url = 'mag-tiki/time-period';

  constructor(private http: HttpClient) {
  }

  getSalesQuantity(): Observable<any> {
    return this.http.get(`${this.url}/sales-quantity`);
  }

}
