import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ItemsService {

  private readonly url = 'mag-erp-admin/incoming-shipments';

  constructor(private http: HttpClient) {
  }

  getList(facilityId: string, lazyLoadEvent: string): Observable<any> {
    return this.http.get(`${this.url}?facilityId=${facilityId}&lazyLoadEvent=${lazyLoadEvent}`);
  }

  create(item: any): Observable<any> {
    return this.http.post(`${this.url}`, item);
  }

  getQuantityAccepted(id: string, productId: string): Observable<any> {
    return this.http.get(`${this.url}/${id}/receive?productId=${productId}`);
  }

  receive(id: string, item: any): Observable<any> {
    return this.http.post(`${this.url}/${id}/receive`, item);
  }

  receiveEntire(id: string): Observable<any> {
    return this.http.post(`${this.url}/${id}/receive-entire`, {});
  }

}
