import {Component, OnInit} from '@angular/core';

import {LazyLoadEvent} from 'primeng/api';

import {Col} from '../../../shared/components/col.model';

import {ItemsService} from './items.service';

@Component({
  selector: 'app-product-features',
  templateUrl: './items.component.html'
})
export class ItemsComponent implements OnInit {

  cols: Array<Col>;
  items: Array<any>;
  totalRecords: number;

  constructor(
    private itemsService: ItemsService
  ) {
  }

  ngOnInit() {
    this.cols = [
      new Col('productFeatureId', 'productFeatureId', 200, 'contains'),
      new Col('abbrev', 'abbrev', 200, 'contains'),
      new Col('description', 'description', 'auto', 'contains')
    ];
  }

  loadLazy(event: LazyLoadEvent) {
    this.itemsService.getList('PftSize', JSON.stringify(event)).subscribe((response) => {
      if (response && typeof response.totalRecords === 'number') {
        this.totalRecords = response.totalRecords;
        this.items = response.listData;
      }
    });
  }

}
