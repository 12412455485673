import {Component, OnDestroy, OnInit} from '@angular/core';

import {LazyLoadEvent} from 'primeng/api';

import {Col} from '../../../shared/components/col.model';

import {BreadcrumbService} from '../../../layout/breadcrumb/breadcrumb.service';

import {ProductService} from '../../service/product.service';
import {AuthService} from '../../../shared/service/auth.service';

@Component({
  selector: 'app-products',
  templateUrl: './items.component.html'
})
export class ItemsComponent implements OnInit, OnDestroy {

  cols: Array<Col>;
  items: Array<any>;
  totalRecords: number;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private productService: ProductService,
    public authService: AuthService
  ) {
  }

  ngOnInit() {
    this.breadcrumbService.setRouterLinkCreate('/Purchases/products/create');

    this.cols = [
      new Col('pseudoId', 'pseudoId', 200, 'contains', 'word-break-all'),
      new Col('productName', 'productName', 'auto', 'contains'),
      new Col('isCombo', 'isCombo', 150, 'contains'),
      new Col('idValue', 'Identification', 150, 'contains')
    ];
  }

  ngOnDestroy(): void {
    this.breadcrumbService.setRouterLinkCreate(null);
  }

  loadLazy(event: LazyLoadEvent) {
    this.productService.getList(JSON.stringify(event)).subscribe((response) => {
      if (response && typeof response.totalRecords === 'number') {
        this.totalRecords = response.totalRecords;
        this.items = response.listData;
      }
    });
  }

}
