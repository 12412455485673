import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ItemsService {

  private readonly url = 'mag-erp-admin/categories';

  constructor(private http: HttpClient) {
  }

  getList(lazyLoadEvent: string): Observable<any> {
    return this.http.get(`${this.url}?lazyLoadEvent=${lazyLoadEvent}`);
  }

  getLeaf(lazyLoadEvent: string): Observable<any> {
    return this.http.get(`${this.url}-leaf?lazyLoadEvent=${lazyLoadEvent}`);
  }

  getParents(productCategoryId: string, lazyLoadEvent: string): Observable<any> {
    return this.http.get(`${this.url}/${productCategoryId}/parents?lazyLoadEvent=${lazyLoadEvent}`);
  }

  getChildren(productCategoryId: string, lazyLoadEvent: string): Observable<any> {
    return this.http.get(`${this.url}/${productCategoryId}/children?lazyLoadEvent=${lazyLoadEvent}`);
  }

  get(id: string): Observable<any> {
    return this.http.get(`${this.url}/${id}`);
  }

  create(item: any): Observable<any> {
    return this.http.post(`${this.url}`, item);
  }

  update(id: string, item: any): Observable<any> {
    return this.http.put(`${this.url}/${id}`, item);
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${this.url}/${id}`);
  }

}
