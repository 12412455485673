import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ItemsService {

  private readonly url = 'mag-erp-admin/products';

  constructor(private http: HttpClient) {
  }

  getPurchasesPrices(productId: string, vendorPartyId: string, minQuantity: number): Observable<any> {
    return this.http.get(`${this.url}/${productId}/purchases-prices?vendorPartyId=${vendorPartyId}&minQuantity=${minQuantity}`);
  }

  getSuppliers(productId: string): Observable<any> {
    return this.http.get(`${this.url}/${productId}/suppliers`);
  }

}
