import {Component, OnDestroy, OnInit} from '@angular/core';

import {LazyLoadEvent} from 'primeng/api';

import {Col} from '../../../shared/components/col.model';

import {BreadcrumbService} from '../../../layout/breadcrumb/breadcrumb.service';

import {ItemsService} from './items.service';
import {AuthService} from '../../../shared/service/auth.service';

@Component({
  selector: 'app-picklists',
  templateUrl: './items.component.html'
})
export class ItemsComponent implements OnInit, OnDestroy {

  facilityId: string;

  cols: Array<Col>;
  items: Array<any>;
  totalRecords: number;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private itemsService: ItemsService,
    public authService: AuthService
  ) {
    this.onChangeFacility = this.onChangeFacility.bind(this);
  }

  ngOnInit() {
    this.breadcrumbService.setRouterLinkCreate('/Logistics/picklists/create');

    this.cols = [
      new Col('workEffortId', 'pseudoId', 120, 'contains'),
      new Col('estimatedStartDate', 'Date', 120, 'contains', '', 'date'),
      new Col('status', 'status', 150, 'contains'),
      new Col('workEffortName', 'Order', 'auto', 'contains')
    ];
  }

  ngOnDestroy(): void {
    this.breadcrumbService.setRouterLinkCreate(null);
  }

  onChangeFacility({facilityId}) {
    if (this.facilityId && this.facilityId !== facilityId) {
      this.facilityId = facilityId;
      this.loadLazy({first: 0, rows: 10});
    } else {
      this.facilityId = facilityId;
    }
  }

  loadLazy(event: LazyLoadEvent) {
    if (this.facilityId) {
      this.itemsService.getList(this.facilityId, JSON.stringify(event)).subscribe((response) => {
        if (response && typeof response.totalRecords === 'number') {
          this.totalRecords = response.totalRecords;
          this.items = response.listData;
        }
      });
    }
  }

}
