import {Component, Input, OnInit} from '@angular/core';

import {Col} from '../../../shared/components/col.model';

import {ShipmentService} from '../service/shipment.service';

@Component({
  selector: 'app-incoming-shipments-expansion',
  templateUrl: './items-expansion.component.html'
})
export class ItemsExpansionComponent implements OnInit {

  @Input() shipmentId: string;

  cols: Array<Col>;
  items: Array<any>;

  constructor(private shipmentService: ShipmentService) {
  }

  ngOnInit() {
    this.cols = [
      new Col('pseudoId', 'pseudoId', 160, null, 'word-break-all'),
      new Col('productName', 'productName', 'auto'),
      new Col('quantity', 'quantity', 120, null, 'text-right', 'number')
    ];

    if (this.shipmentId) {
      this.shipmentService.getItems(this.shipmentId).subscribe(({listData}) => {
        this.items = listData;
      });
    }
  }

}
