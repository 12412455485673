import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ItemsService {

  private readonly url = 'mag-erp-admin/picklists';

  constructor(private http: HttpClient) {
  }

  getList(facilityId: string, lazyLoadEvent: string): Observable<any> {
    return this.http.get(`${this.url}?facilityId=${facilityId}&lazyLoadEvent=${lazyLoadEvent}`);
  }

  get(id: string): Observable<any> {
    return this.http.get(`${this.url}/${id}`);
  }

  pdf(id: string): Observable<any> {
    return this.http.get(`${this.url}/${id}/pdf`);
  }

  create(item: any): Observable<any> {
    return this.http.post(`${this.url}`, item);
  }

  cancel(id: string): Observable<any> {
    return this.http.patch(`${this.url}/${id}/cancel`, null);
  }

  begin(id: string): Observable<any> {
    return this.http.patch(`${this.url}/${id}/begin`, null);
  }

  complete(id: string): Observable<any> {
    return this.http.patch(`${this.url}/${id}/complete`, null);
  }

  getOrders(id: string, lazyLoadEvent: string): Observable<any> {
    return this.http.get(`${this.url}/${id}/items/orders?lazyLoadEvent=${lazyLoadEvent}`);
  }

  getProducts(id: string, lazyLoadEvent: string): Observable<any> {
    return this.http.get(`${this.url}/${id}/items/products?lazyLoadEvent=${lazyLoadEvent}`);
  }

  getStatusHistories(orderId: string): Observable<any> {
    return this.http.get(`${this.url}/${orderId}/statusHistories`);
  }

  getInputOrders(facilityId: string, lazyLoadEvent: string): Observable<any> {
    return this.http.get(`${this.url}/orders?facilityId=${facilityId}&lazyLoadEvent=${lazyLoadEvent}`);
  }

  printPdf(workEffortId: string): Observable<any> {
    return this.http.get(`${this.url}/${workEffortId}/items/products/pdf`, {responseType: 'blob'});
  }

  printLabel(data: any): Observable<any> {
    return this.http.post(`${this.url}/print-label`, {listData: data}, {responseType: 'blob'});
  }

}
